import React, { useState, useEffect } from "react";
import "./App.css";
import { ref, set, get, child, onValue, off } from "firebase/database";
import db from "./firebasev2";
import { useParams } from "react-router-dom";
import LightingControlItem from "./lightingcontrol";
import Habits from "./habits";
import axios from "axios";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";

//const analytics = getAnalytics(app);

function Controlv2() {
  let { machine, key, code, token } = useParams();

  const [devices, setDevices] = useState([]);
  const [habits, setHabits] = useState({});
  const [dbRef, setDbRef] = useState(null);
  const [zoneName, setZoneName] = useState("");

  const executeHabit = (data) => {
    //console.log(Object.keys(data));

    console.log("ready to execute habit");

    Object.keys(data).map((k) => {
      //console.log(k + ":" + data[k]);
      //console.log(`${machine}/${key}/devices/${k}`);
      const kref = ref(db, `${machine}/${key}/devices/${k}/level`);
      set(kref, {
        action: "web",
        level: data[k],
      });
    });
  };

  const addHabit = async (icon) => {
    //console.log(Object.keys(data));
    console.log("ready to add habit");
    const dbref = ref(db);
    const snapshot = await get(child(dbref, `${machine}/${key}/devices`));
    const devices = snapshot.val();
    var formatteddevices = {};

    Object.keys(devices).map((device) => {
      formatteddevices = {
        ...formatteddevices,
        [device]: devices[device].level.level,
      };
    });

    const habitsnapshot = await get(child(dbref, `${machine}/${key}/habits`));
    let currentHabits = habitsnapshot.val();
    console.log({ [icon]: formatteddevices });
    let newtHabits = {
      ...currentHabits,
      [icon]: formatteddevices,
    };

    const href = ref(db, `${machine}/${key}/habits`);
    set(href, newtHabits);

    setHabits(newtHabits);
    console.log(newtHabits);
    //console.log(formatteddevices);

    // Object.keys(data).map((k) => {
    //   //console.log(k + ":" + data[k]);
    //   //console.log(`${machine}/${key}/devices/${k}`);
    //   const kref = ref(db, `${machine}/${key}/devices/${k}/level`);
    //   set(kref, {
    //     action: "web",
    //     level: data[k],
    //   });
    // });
  };

  const removeHabit = async (icon) => {
    console.log("ready to remove habit: " + icon);
    const dbref = ref(db);
    const habitsnapshot = await get(child(dbref, `${machine}/${key}/habits`));
    let currentHabits = habitsnapshot.val();

    let newtHabits = {
      ...currentHabits,
    };
    delete newtHabits[icon];
    //console.log(newtHabits);

    const href = ref(db, `${machine}/${key}/habits`);
    set(href, newtHabits);

    setHabits(newtHabits);
    // console.log(newtHabits);
  };

  useEffect(() => {
    async function fetchData() {
      // first, get the full token from token and sign in
      try {
        const response = await axios({
          method: "post",
          url: `https://tenses.app/RetrieveFullToken`,
          //url: "https://asia-east2-instant-lighting.cloudfunctions.net/RetrieveFullToken",
          data: {
            machineId: machine,
            uniquekey: key,
            token: token,
          },
        });

        //dev
        // const devToken =
        //   "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY5NzA4MDM4MCwiZXhwIjoxNjk3MDgzOTgwLCJpc3MiOiJpbnN0YW50LWxpZ2h0aW5nQGFwcHNwb3QuZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6Imluc3RhbnQtbGlnaHRpbmdAYXBwc3BvdC5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoiYTM0ZWQ2ZWIyMGJkNDE2OWU0OThlYThlM2ZmOWMwYzljNzdhMjQ2YTQ4MTkxMzI4YjA3ZmRmMDc2ODEzMGNhYjEifQ.R7gHLO0QOqaMCG8LVNBJfgh1kTF0M6AsWWjzPFBB3yEPPDTW5mXenmKAaFqApGkBH5SRFj9EBX5tns8XnAH3sG9BW_VRBm-JKNDbMYMfAs1-jrZh9w_ISrLg2kjGrdb14seNnmZ_m1ixY3_OryqBz8Y9UFE_9GyfKI1tZXo9xt8FirHfcEgEk9vLWdwtVjJxfGDZ6EdlVK0Chmjalkk0OeX09TylI7S37yPb0vnQsaxQzPJy8zRqSLW2NdFuf2s-yrpzNFapbXPk2a_mn_h272OFzf2343U5DVgPOiV0lNxr04xzEpIlt3_lmQ65VO8IZblfRDyQtkiRhiylNzsiog";

        const auth = getAuth();
        const userCredential = await signInWithCustomToken(
          auth,
          //devToken
          response.data.customToken
        );
        if (userCredential != null) {
          // Signed in
          const user = userCredential.user;
          console.log("instant lighting login success");
        }
      } catch (error) {
        console.log(error);
        setZoneName("Invalid Link. Please verify. ");
        return;
      }
      const dbref = ref(db);
      console.log("ready to get data");
      setDbRef(dbref);

      const snapshot = await get(child(dbref, `${machine}/${key}/access/code`));
      console.log(snapshot.val());
      if (snapshot.exists() && snapshot.val() === code) {
        console.log("correct code");
        const zonename = await get(child(dbref, `${machine}/${key}`));
        setZoneName(zonename.val().name);
      } else {
        setZoneName("Invalid Link. Please verify. ");
        console.log("Invalid link");
        return;
      }

      const validRef = ref(db, `${machine}/${key}/access/code`);
      // //console.log(dbRef);
      // //console.log(dbref);

      onValue(validRef, (snapshot) => {
        if (snapshot.val() === null) {
          setDevices(null);
          setHabits(null);
          setZoneName("Session Expired");
          console.log("Session Expired");
          off(validRef, "value");
          const auth = getAuth();
          signOut(auth);
          //console.log(validationcheck);
        }
        //updateStarCount(postElement, data);
      });

      get(child(dbref, `${machine}/${key}/access`))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            console.log("exist!");
            if (snapshot.val().code === code) {
              console.log("code is correct! geeting devices...");
              const devicenode = await get(
                child(dbref, `${machine}/${key}/devices`)
              );
              const habitsnode = await get(
                child(dbref, `${machine}/${key}/habits`)
              );
              let habits = {};
              for (let key in habitsnode.val()) {
                let object = habitsnode.val()[key];
                habits[key] = object;
              }

              setHabits(habits);

              let result = [];
              for (let key in devicenode.val()) {
                let object = devicenode.val()[key];
                result.push(object);
              }

              setDevices(result);
            }
          } else {
            console.log("Invalid link");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);
  return (
    <div className="App">
      <nav>
        <h1>{zoneName}</h1>
      </nav>
      <div
        style={{
          margin: 20,
          paddingRight: 30,
          overflow: "auto",
          height: "inherit",
          display: "block",
          paddingBottom: 100,
        }}
      >
        {devices && (
          <Habits
            data={habits}
            showFab={devices.length > 0}
            addHabit={addHabit}
            removeHabit={removeHabit}
            executeHabit={executeHabit}
          />
        )}
        {devices &&
          devices.map((item, index) => (
            <LightingControlItem
              key={item.id}
              id={item.id}
              name={item.name}
              level={item.level.level}
              min={item.min}
              max={item.max}
              poweron={item.poweron}
              dbRef={ref(
                db,
                `${machine}/${key}/devices/device${item.id}/level`
              )}
            />
          ))}
      </div>
    </div>
  );
}

export default Controlv2;
