import React, { useEffect, useState } from "react";
import { set, onValue, off } from "firebase/database";
import { Switch, Slider, Grid } from "@mui/material";

const LightingControlItem = (props) => {
  const [isEnabled, setIsEnabled] = useState(props.level === 0 ? false : true);
  const [levelValue, setLevel] = useState(props.level);
  const min = props.min;
  const max = props.max;
  const poweron = props.poweron;
  const level = props.level;
  const name = props.name;
  const id = props.id;
  const dbRef = props.dbRef;

  const LevelChange = (level) => {
    //mqttclient.setLevel(id, level);
    console.log("level change:" + level);
    if (level > 0) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
    setLevel(level);

    // update db
    console.log("update rtdb");
    set(dbRef, {
      action: "web",
      level: level,
    });
  };

  const LevelUpdate = (level) => {
    console.log("level update:" + level);
    if (level > 0) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
    setLevel(level);
  };

  const toggleSwitch = () => {
    setIsEnabled((previousState) => !previousState);
    //true => off
    if (isEnabled) {
      LevelChange(0);
    } else {
      LevelChange(poweron);
    }
  };

  const handleChange = (e, v) => {
    console.log(v);
    LevelChange(v);
  };

  const handleDrag = (e, v) => {
    setLevel(v);
  };

  //   const valueLabelFormat = (e)= {
  //       return `${e}%`;
  //   }

  useEffect(() => {
    // //console.log(dbRef);
    // //console.log(dbref);
    //off(dbRef, "value");

    onValue(dbRef, (snapshot) => {
      if (snapshot.val() === null) {
        off(dbRef, "value");
        //console.log(validationcheck);
      } else {
        const value = snapshot.val();
        //if (value.action != "web") {
        LevelUpdate(value.level);
        //}
      }
      //updateStarCount(postElement, data);
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        {name}
      </Grid>
      <Grid item xs={2}>
        {levelValue}%
      </Grid>
      <Grid item xs={2}>
        <div style={{ paddingTop: 3 }}>
          <Switch checked={isEnabled} onChange={toggleSwitch} />
        </div>
      </Grid>
      <Grid item xs={10}>
        <div style={{ paddingLeft: 15 }}>
          <Slider
            min={min}
            max={max}
            valueLabelDisplay="auto"
            onChange={handleDrag}
            onChangeCommitted={handleChange}
            value={levelValue}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default LightingControlItem;
