import React, { useState, useEffect } from "react";
import "./App.css";
import { ref, set, get, child, onValue, off } from "firebase/database";
import db from "./firebase";
import { useParams } from "react-router-dom";
import LightingControlItem from "./lightingcontrol";

//import { getAnalytics } from "firebase/analytics";

//const analytics = getAnalytics(app);

function Control() {
  let { key, code } = useParams();

  const [devices, setDevices] = useState([]);
  const [dbRef, setDbRef] = useState(null);
  const [zoneName, setZoneName] = useState("");

  useEffect(() => {
    async function fetchData() {
      // first, get the full token from token and sign in
      // `try {
      //   const response = await axios({
      //     method: "post",
      //     url: `https://tenses.app/RetrieveFullToken`,
      //     data: {
      //       token: token,
      //     },
      //   });
      //   const auth = getAuth();
      //   const userCredential = await signInWithCustomToken(
      //     auth,
      //     response.data.customToken
      //   );
      //   if (userCredential != null) {
      //     // Signed in
      //     const user = userCredential.user;
      //     console.log("instant lighting login success");
      //   }
      // } catch (error) {
      //   console.log(error);
      //   setZoneName("Invalid Link. Please verify. ");
      // }`
      const dbref = ref(db);
      console.log("ready to get data");
      setDbRef(dbref);

      const snapshot = await get(child(dbref, `${key}/access/code`));
      console.log(snapshot.val());
      if (snapshot.exists() && snapshot.val() === code) {
        console.log("correct code");
        const zonename = await get(child(dbref, `${key}`));
        setZoneName(zonename.val().name);
      } else {
        setZoneName("Invalid Link. Please verify. ");
        console.log("Invalid link");
        return;
      }

      const validRef = ref(db, `${key}/access/code`);
      // //console.log(dbRef);
      // //console.log(dbref);

      onValue(validRef, (snapshot) => {
        if (snapshot.val() === null) {
          setDevices(null);
          setZoneName("Session Expired");
          console.log("Session Expired");
          off(validRef, "value");
          //console.log(validationcheck);
        }
        //updateStarCount(postElement, data);
      });

      get(child(dbref, `${key}/access`))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            console.log("exist!");
            if (snapshot.val().code === code) {
              console.log("code is correct! geeting devices...");
              const devicenode = await get(child(dbref, `${key}/devices`));

              let result = [];
              for (let key in devicenode.val()) {
                let object = devicenode.val()[key];
                result.push(object);
              }

              setDevices(result);
            }
          } else {
            console.log("Invalid link");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);
  return (
    <div className="App">
      <nav>
        <h1>{zoneName}</h1>
      </nav>
      <div
        style={{
          margin: 20,
          paddingRight: 30,
          overflow: "auto",
          height: "inherit",
          display: "block",
          paddingBottom: 100,
        }}
      >
        {devices &&
          devices.map((item, index) => (
            <LightingControlItem
              key={item.id}
              id={item.id}
              name={item.name}
              level={item.level.level}
              min={item.min}
              max={item.max}
              poweron={item.poweron}
              dbRef={ref(db, `${key}/devices/device${item.id}/level`)}
            />
          ))}
      </div>
    </div>
  );
}

export default Control;
