import React, { useRef, useEffect, useState } from "react";
import { set, onValue, off } from "firebase/database";
import { Button, Drawer } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Icon from "@mdi/react";
import {
  mdiLightbulbOnOutline,
  mdiLightbulbOff,
  mdiPowerSleep,
  mdiTelevisionOff,
  mdiTelevision,
  mdiSleep,
  mdiMusic,
  mdiMusicOff,
  mdiBookOpenBlankVariant,
  mdiLockOutline,
  mdiLockOpenVariantOutline,
  mdiYoutube,
  mdiPlus,
} from "@mdi/js";

const Habits = ({ data, showFab, executeHabit, addHabit, removeHabit }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  let clickHoldTimer = null;
  let touchHoldTimer = null;

  const handleTouchStart = (icon) => {
    touchHoldTimer = setTimeout(() => {
      //Action to be performed after holding down mouse
      removeHabit(icon);
    }, 1000);
  };

  const handleTouchEnd = () => {
    clearTimeout(touchHoldTimer);
  };

  const addHabits = (icon) => {
    setOpenDrawer(false);
    //console.log(icon);
    addHabit(icon);
  };

  // Create an array of key-value pairs from the object
  const keyValuePairs = Object.entries(data);
  const iconMap = {
    "lightbulb-on-outline": mdiLightbulbOnOutline,
    "lightbulb-off": mdiLightbulbOff,
    "power-sleep": mdiPowerSleep,
    "television-off": mdiTelevisionOff,
    television: mdiTelevision,
    sleep: mdiSleep,
    music: mdiMusic,
    "music-off": mdiMusicOff,
    "book-open-blank-variant": mdiBookOpenBlankVariant,
    "lock-outline": mdiLockOutline,
    "lock-open-variant-outline": mdiLockOpenVariantOutline,
    youtube: mdiYoutube,
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        {keyValuePairs.map(([key, value]) => (
          <div key={key} style={{ padding: 2, flex: "1", textAlign: "center" }}>
            <Button
              variant="outlined"
              onClick={() => {
                executeHabit(value);
              }}
              onTouchStart={() => {
                handleTouchStart(key);
              }}
              onTouchEnd={handleTouchEnd}
            >
              <Icon path={iconMap[key]} size={2} color={"gray"} />
            </Button>
          </div>
        ))}
        {showFab && Object.entries(data).length < 4 && (
          <Icon
            path={mdiPlus}
            size={"32px"}
            onClick={() => {
              setOpenDrawer(true);
            }}
            color="#333333"
            aria-label="add"
            style={{
              margin: 0,
              top: 18,
              right: 15,
              bottom: "auto",
              left: "auto",
              position: "fixed",
            }}
          />
        )}
      </div>

      <React.Fragment key={"left"}>
        <Drawer
          anchor={"left"}
          open={openDrawer}
          onClick={() => {
            setOpenDrawer(false);
          }}
        >
          {Object.keys(iconMap).map((i) => (
            <Icon
              onClick={() => {
                addHabits(i);
              }}
              path={iconMap[i]}
              size={2}
              color={"gray"}
              style={{ padding: 10 }}
            />
          ))}
        </Drawer>
      </React.Fragment>

      {/* {keyValuePairs.map(([key, value]) => (
        <Icon path={iconMap[key]} size={3} color={"gray"} />
        // <Button
        //   variant="contained"
        //   key={key}
        //   onClick={() => {
        //     exectue(value);
        //   }}
        // >
        //   {key}
        // </Button>
      ))} */}

      {/* {keyValuePairs.map(([key, value]) => (
        <button key={key}>
          {key}: {value}
        </button>
      ))} */}
    </div>
  );
};

export default Habits;
