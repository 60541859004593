import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
//import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6_2X7tbu4Ey872I4kEZvH7RuUp-9d0dk",
  authDomain: "instant-lighting.firebaseapp.com",
  databaseURL:
    "https://instant-lighting-rtdb-v2.asia-southeast1.firebasedatabase.app/",
  projectId: "instant-lighting",
  storageBucket: "instant-lighting.appspot.com",
  messagingSenderId: "1022221702621",
  appId: "1:1022221702621:web:df9d8f9d1ce49eaa6e9234",
  measurementId: "G-ECMMGWVTTM",
};

// Initialize Firebase
console.log("init new db");
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
export default db;
