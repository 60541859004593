import React from "react";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  // Check if the URL path contains "/v2/"
  console.log(window.location.pathname);
  const isV2Path = window.location.pathname.includes("v2/");

  // Dynamically import the appropriate component based on the condition
  const ControlComponent = isV2Path
    ? require("./controlv2").default
    : require("./control").default;

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route
            path="/v2/:machine/:key/:code/:token"
            component={ControlComponent}
          />
        </Switch>
        <Switch>
          <Route path="/il/:key/:code/" component={ControlComponent} />
        </Switch>
      </div>
      <footer>
        <p>Instant Lighting - Powered by LiFE</p>
      </footer>
    </Router>
  );
}

export default App;
